.custom-search-container {
  position: relative;
 
}

.search-bar-wrapper {
  display: flex;
  align-items: center;
  background-color: #e7e1d1;
  border-radius: 6px;
  padding: 3px .5em;
  width: 12em; /* Fixed width */
  top: '2em';
}

.search-icon {
  color: #333;
}

.custom-search-input {
  border: none;
  outline: none;
  background-color: transparent;
  flex: 1;
  margin-left: 0.5em;
  font-family: 'Bricolage grotesque';
  color: #333;
}

.search-results-dropdown {
  position: absolute;
  top: 2em; /* Adjust this value as needed */
  left: 0;
  z-index: 9999;
  width: 15em; /* Fixed width */
  border-radius: 12px;
  max-height: 40em;
  overflow-y: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.results-list {
  background-color: white;
  border-radius: 18px;
  padding: 0.5em;
}

.results-list .MuiListItem-root {
  padding: 0.5em 1em;
}

.result-text .MuiListItemText-primary {
  font-size: 0.9em;
}

.result-text .MuiListItemText-secondary {
  font-size: 0.75em;
  color: #666;
}

@media (max-width: 600px) {
  .search-bar-wrapper {
    width: 80%;
  }
  .search-results-dropdown {
    width: 80%; /* Fixed width for mobile view */
  }
}
