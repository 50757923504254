#single-guide-header1 {
    font-size: 5vw; /* Scales based on viewport width */
    margin-top: 2em; /* Smaller margin for smaller screens */
    font-weight: 600;
    text-align: center;
  }
  
  #course-subheader {
    font-size: 2em; /* Adjust font-size */
  }
  
  .icon-horizontal {
    display: flex;
    justify-content: center;
    gap: 0px; /* Adjusts the gap between icons */
  }
  
  #container {
    padding: 1em; /* Add padding to the container */
    position: relative;
    bottom: 10em;
  }


  .ul li {
    line-height: 1.5;
  
  }

  
  .body2 {
    font-size: 16px;
  }
  
  .course-icon {
    width: 10px;
    font-size: 10px;
  }

  .icon-horizontal .course-icon {
    width: 10px;
    height: 10px;
  }
  

  
  @media (max-width: 768px) {
    /* Styles for tablets or smaller screens */
    #course-icon {
      width: 8vw;
    }
  
    .course-tag-button {
      font-size: 1em; /* Adjust font size */
    }
  }
  
  @media (max-width: 480px) {
    /* Styles for mobile devices */
    #single-guide-header1 {
      font-size: 6vw; /* Larger font-size for smaller screens */
    }
  
    .course-tag-button {
      font-size: 0.8em; /* Smaller font size */
    }
  }

  .jumbotron {
    display: none;
  }
  

  .free-text {
    border-bottom: 2px solid #fcaa97 !important;
    margin-top: '3em';
  }

  .li {
    margin-top: 20px;
  }

  .single-divider-1 {
    max-width: 75%;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center; 
    gap: 10px; 

  }







.course-icon {
    width: 20px; /* Smaller icon size */
    height: auto; /* Maintain aspect ratio */
    margin: 0 10px; /* Horizontal margin for spacing */
    vertical-align: middle; /* Align icons with text */
  }
  

  
  .icon-horizontal {
    display: flex;
    justify-content: center; /* Center align icons */
    gap: 0px; /* Gap between icons */
  }
  
  .course-company {
    font-weight: bold; /* Emphasize company name */
    font-size: 18px; /* Font size for course company name */
    display: flex;
    align-items: center; /* Align icon and text */
 
  }


#pilltop {
  margin-top: '0px';
}




