/* Navbar.css - CSS rules for styling the Navbar */
.navbar {
    background-color: #faf3e9; /* Background color for the navbar */
    padding: 0px; /* Padding to provide space within the navbar */
  }
  
  .navbar .navbar-logo {
    color: white; /* Text color for the logo */
    font-size: 1.5rem; /* Adjust font size */
    font-weight: bold; /* Bold font */
    text-transform: none; /* Ensure text isn't capitalized */
    position: relative;
    left: 0px
  }
  
  .navbar .navbar-link {
    color: white; /* Text color for navbar links */
    text-decoration: none; /* Remove underline */
    margin-right: 15px; /* Space between links */
  }
  
  .navbar .navbar-link:hover {
    text-decoration: underline; /* Underline on hover */
  }

  .title-color {
    color: #2C3E50;
  }
  






  /* Keyframes for the pulse grow effect */
  @keyframes pulseGrow {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2); /* Grows 20% at the peak of the pulse */
    }
    100% {
      transform: scale(1); /* Returns to original size */
    }
  }
  
  /* Apply the pulse grow effect to an element on hover */
  #the-logo img {
    transition: transform 0.2s ease-in-out; /* Smooth transition for any transformation */
  }
  
  #the-logo img:hover {
    animation: pulseGrow 1s ease-in-out; /* Duration and easing for smooth pulse */
    animation-iteration-count: infinite; /* Repeat the pulse while hovering */
  }

  .menu-button {
    /* Example of adjusting button size for tablet */
    padding: 10px 20px;
  }

  .menu-items {
    /* Example of changing layout for tablet */
    display: flex;
    flex-direction: column;
  }





  .two-column-menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 10px;
  }
  
  .two-column-menu .MuiMenuItem-root {
    width: auto; /* Adjust to ensure menu items fit within grid cells */
  }


  .menu-flex {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line */
    max-width: 400px; /* Adjust this width as needed */
}

.menu-flex a {
    flex: 1 1 50%; /* Each item takes up 50% of the width */
    text-decoration: none;
    box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
}