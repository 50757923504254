h2 {
    color: 'white';
    font-family: 'Bricolage Grotesque', sans-serif;
}

/*   background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=FFE8C8);   */
.highlight8 {
   
    margin: -2px -6px;
    padding:  2px  6px;
    color: #333;
}

#highlighted-text {
    color: 'white';
}



.glow-on-hover {
    
    border: none;
    outline: none;
    color: #fff;
    background: #e7e1d1;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';


    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #2C3E50;
}



.glow-on-hover:hover:before {
    opacity: 1;
}
/* pill background */
.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffe6c7;
    left: 0;
    top: 0;
    border-radius: 16px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}