#single-guide-header {
  font-size: 20px; /* Adjusted font size */
  font-weight: 500;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 2em;
}

.dots {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.course-subheader-7 {
  font-size: 18px; /* Adjusted font size */
  font-weight: 500;
  text-align: center;
  margin-top: 2em;
  color: 'black';
}

.course-subheader-2 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 1.5;
  padding: 0 30px; /* Ensure padding on both sides */
}

.course-subheader-3 {
  font-size: 14px; /* Adjusted font size for source text */
  font-weight: 500;
  text-align: left;
  margin-top: 10em;
}

.glossary-word {
  font-weight: 500;
  font-size: 16px;
  color: 'white'
}

.glossary-container {
  padding: 20px;
  position: relative;
  bottom: 0em;
  top: 0em;
}

.ul {
  color: 'white';
}

.glossary-content {
  max-width: 1000px; /* Ensure a consistent width */
  margin: 0 auto; /* Center the content */
  line-height: 1.5;
}

/* Media query for smaller screens (below 600px) */
@media (max-width: 600px) {
  #single-guide-header {
    font-size: 20px; /* Adjusted font size for smaller screens */
    margin-top: 1em;
  }

  .course-subheader-1 {
    font-size: 16px; /* Reduced font size */
    color: 'black';
  }

  .course-subheader-2 {
    font-size: 16px; /* Reduced font size */
    padding: 0 10px; /* Adjust padding for smaller screens */
    margin-top: 0px;
  }

  .glossary-content {
    padding: 10px; /* Reduced padding for smaller screens */
  }
}
