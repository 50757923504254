.container {
    max-width: 1290px;  /* Maximum width */
    margin: 0 auto;     /* Center the content */
    padding: 0 20px;    /* Padding for inner spacing */
  }
  
  /* Responsive adjustment for smaller screens */
  @media (max-width: 600px) {
    .container {
      padding: 0 10px;  /* Adjust padding for small screens */
    }
  }
  