.tag-conversational-ai {
    color: rgb(63, 189, 63);
    background-color: #dcfce7;
  }
  
  .tag-video {
    color: rgb(211, 123, 206);
    background-color: #fde7f2;
  }

  .tag-creative {
    color: #f58f37; /* Dark Pumpkin */
    background-color: #fee8da; /* Lighter Pumpkin */
  }
  
  .tag-text-\&-language{
     color: #8c8dd7; /* Darker raspberry shade */
    background-color: #e7e7f9; /* Light raspberry shade */
  }
  
  .tag-workflow {
    color: #00b6b6; /* Dark Saffron */
    background-color: #dafcf3; /* Lighter Saffron */
  }
  
  .tag-business {
    color: #a488de; /* Dark Vermilion */
    background-color: #e7e2f7; /* Lighter Vermilion */
  }
  
  .tag-teams {
    color: #ed86bd; /* Dark Electric Purple */
    background-color: #ffe4ea; /* Lighter Electric Purple */
  }
  
  .tag-social-media {
    color: #6095ea; /* Dark Button Blue */
    background-color: #dfeafd; /* Lighter Button Blue */
  }
  
  .tag-developers {
    color: #38abc7; /* Dark Sunglow */
    background-color: #d9ecf8; /* Lighter Sunglow */
  }
  
  
  
  .tag-artistic {
    color: #2d99b4; /* Dark Medium Aquamarine */
    background-color: #d4f8ee; /* Lighter Medium Aquamarine */
  }
  
  .tag-gaming {
    color: #c7a600; /* Darker banana shade */
    background-color: #fcf2c8; /* Light banana shade */
  }

  .tag-funny {
    color: #e67ecf; /* Dark Blue NCS */
    background-color: #fce2f4; /* Lighter Blue NCS */
  }


  .tag-voice-\&-speech {
    color: #da8e4b; /* Darker shade for text */
    background-color: #feebe2; /* Lighter shade for background */
  }

  .tag-health-\&-wellness {
    color: #d48f45; /* Darker mango shade */
    background-color: #feeccd; /* Light mango shade */
  }

  .tag-science-\&-research {
    color: #b366f7; /* Darker mango shade */
    background-color: #f1e2ff; /* Light mango shade */
  }
  
  .tag-legal {
    color: #e67582; /* Darker watermelon shade */
    background-color: #fcdce3; /* Light watermelon shade */
  }
  
  .tag-chatbots {
    color: #c7a600; /* Darker banana shade */
    background-color: #fcf2c8; /* Light banana shade */
  }
  
  .tag-music {
    color: #6d9f45; /* Darker kiwi shade */
    background-color: #e1fcda; /* Light kiwi shade */
  }
  
  .tag-image {
    color: #d08787; /* Darker blueberry shade */
    background-color: #fae4e4; /* Light blueberry shade */
  }
  
  .tag-learning {
    color: #3db5ca; /* Darker strawberry shade */
    background-color: #d4f6fa; /* Light strawberry shade */
  }
  
  .tag-ethical-ai {
    color: #d69256; /* Darker peach shade */
    background-color: #fff2e7; /* Light peach shade */
  }
  
  .tag-real-estate {
    color: #dd5252; /* Darker grape shade */
    background-color: #fce1e1; /* Light grape shade */
  }
  
  .tag-finance {
    color: #7cb342; /* Darker lime shade */
    background-color: #e8f7d7; /* Light lime shade */
  }
  
  .tag-education {
    color: #82a88c; /* Darker raspberry shade */
    background-color: #e2fae4; /* Light raspberry shade */
  }
  
  .tag-voice {
    color: #54a369; /* Darker raspberry shade */
    background-color: #e2fae4; /* Light raspberry shade */
  }


  .tag-writing-assistants {
    color: #b497d5; /* Darker raspberry shade */
    background-color: #f2e9f8; /* Light raspberry shade */
  }

  .tag-self-improvement {
    color: #c578bf; /* Darker raspberry shade */
    background-color: #fadeef; /* Light raspberry shade */
  }

  .tag-avatars {
    color: #8597cd; /* Darker raspberry shade */
    background-color: #dee4fa; /* Light raspberry shade */
  }

  .tag-resume-\&-job-search {
    color: #6ead7a; /* Darker raspberry shade */
    background-color: #defae4; /* Light raspberry shade */
  }

  .tag-fashion {
    color: #e08fbe; /* Darker raspberry shade */
    background-color: #fbe5f4; /* Light raspberry shade */
  }

  .tag-relaxation {
    color: #b6ae43; /* Darker raspberry shade */
    background-color: #fcf8e9; /* Light raspberry shade */
  }

  .tag-travel {
    color: #58ce4b; /* Darker raspberry shade */
    background-color: #e7f8e2; /* Light raspberry shade */
  }

  .tag-historical {
    color: #c6a633; /* Darker raspberry shade */
    background-color: #f2ebd1; /* Light raspberry shade */
  }

  .tag-image-generators {
    color: #d485cd; /* Darker raspberry shade */
    background-color: #fcecf9; /* Light raspberry shade */
  }

  .tag-student {
    color: #d48585; /* Darker raspberry shade */
    background-color: #fceced; /* Light raspberry shade */
  }

  .tag-shopping {
    color: #6ab588; /* Darker raspberry shade */
    background-color: #e1fbf2; /* Light raspberry shade */
  }

  .tag-healthcare {
    color: #b56a6a; /* Darker raspberry shade */
    background-color: #fbe1e1; /* Light raspberry shade */
  }

  .tag-video-generators{
    color: #969ed0; /* Darker raspberry shade */
    background-color: #edeffc; /* Light raspberry shade */
  }

  .tag-agents {
    color: #6aa5b5; /* Darker raspberry shade */
    background-color: #e1f6fb; /* Light raspberry shade */
  }

  .tag-mobile {
    color: #b58c6a; /* Darker raspberry shade */
    background-color: #fbf0e1; /* Light raspberry shade */
  }

  .tag-deepfakes {
    color: #8c8dd7; /* Darker raspberry shade */
    background-color: #e7e7f9; /* Light raspberry shade */
  }

  .tag-entertainment {
    color: #2d99b4; /* Dark Medium Aquamarine */
    background-color: #d4f8ee; /* Lighter Medium Aquamarine */
  }

  .tag-productivity {
    color: #de8686; /* Darker raspberry shade */
    background-color: #fbe1e1; /* Light raspberry shade */
  }

  .tag-food-\&-cooking {
    color: #6aa5b5; /* Darker raspberry shade */
    background-color: #e1f6fb;
  }

  .tag-sim-racing {
    color: #8c8dd7; /* Darker raspberry shade */
    background-color: #e7e7f9; /* Light raspberry shade */
  }


  .tag-no-code {
    color: #6ab588; /* Darker raspberry shade */
    background-color: #e1fbf2; /* Light raspberry shade */
  }



  .tag-search {
    color: #3db5ca; /* Darker strawberry shade */
    background-color: #d4f6fa; /* Light strawberry shade */
  }

  .tag-marketing {
    color: #de8686; /* Darker raspberry shade */
    background-color: #fbe1e1; /* Light raspberry shade */
  }
  
  .tag-podcasts {
    color: #8c8dd7; /* Darker raspberry shade */
    background-color: #e7e7f9; /* Light raspberry shade */
  }

  .tag-religion {
    color: #c6a633; /* Darker raspberry shade */
    background-color: #f2ebd1; /* Light raspberry shade */
  }





  .price-free {
    color:  #69b4ff;
  }
  
  .price-freemium {
    color:  rgb(130, 194, 149);
  }
  
  .price-paid {
    color: #9370DB;
  }

