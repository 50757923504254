
  
  .toolcard-chip.tag-business {
    color: #5a63b8; /* Dark Vivid Purple */
    background-color: #d5dff2; /* Lighter Vivid Purple */
  }

  .toolcard-chip.tag-entrepreneurship {
    color: #5ab889; /* Dark Vivid Purple */
    background-color: #d5f2e8; /* Lighter Vivid Purple */
  }

  .toolcard-chip.tag-war-stories {
    color: #1a4a32; /* Dark Vivid Purple */
    background-color: #a1cabb; /* Lighter Vivid Purple */
  }

  .toolcard-chip.tag-self-improvement{
    color: #418d93; /* Dark Vivid Purple */
    background-color: #c5eceb; /* Lighter Vivid Purple */
  }

  .toolcard-chip.tag-tv-and-film{
    color: #51a0aa; /* Dark Vivid Purple */
    background-color: #b5ece5; /* Lighter Vivid Purple */
  }

  .toolcard-chip.tag-exposing-scams{
    color: #51a0aa; /* Dark Vivid Purple */
    background-color: #b5ece5; /* Lighter Vivid Purple */
  }
  
 

  .toolcard-chip.toolcard-chip.tag-society-and-culture {
    color: #3fb873; /* Darker shade for text */
    background-color: #e2feef; /* Lighter shade for background */
  }


  .toolcard-chip.tag-news-and-current-events {
    color: #b366f7; /* Darker mango shade */
    background-color: #f1e2ff; /* Light mango shade */
  }
  
  .toolcard-chip.tag-true-crime {
    color: #f88787; /* Darker watermelon shade */
    background-color: #f4b6b6; /* Light watermelon shade */
  }

  .toolcard-chip.tag-horror-fiction {
    color: #f88787; /* Darker watermelon shade */
    background-color: #f4b6b6; /* Light watermelon shade */
  }

  .toolcard-chip.tag-formula-1-racing {
    color: #f88787; /* Darker watermelon shade */
    background-color: #f4b6b6; /* Light watermelon shade */
  }
  
  .toolcard-chip.tag-celebrity {
    color: #cc49e3; /* Darker watermelon shade */
    background-color: #f3b6f4; /* Light watermelon shade */
  }
  


  .toolcard-chip.tag-politics {
    color: #3353c6; /* Darker raspberry shade */
    background-color: #d1d7f2; /* Light raspberry shade */
  }


  .toolcard-chip.tag-history{
    color: #c69733; /* Darker raspberry shade */
    background-color: #d1f2ee; /* Light raspberry shade */
  }

  .toolcard-chip.tag-cars-and-driving{
    color: #c69733; /* Darker raspberry shade */
    background-color: #d2f2d1; /* Light raspberry shade */
  }

  .toolcard-chip.tag-comedy{
    color: #6aacb5; /* Darker raspberry shade */
    background-color: #e1f7fb;
  }


  .toolcard-chip.tag-technology{
    color: #c68933; /* Darker raspberry shade */
    background-color: #efd1f2; /* Light raspberry shade */
  }

  .toolcard-chip.tag-disaster-events{
    color: #c68933; /* Darker raspberry shade */
    background-color: #f3bfa7; /* Light raspberry shade */
  }

  .toolcard-chip.tag-science{
    color: #3353c6; /* Darker raspberry shade */
    background-color: #d1d7f2; /* Light raspberry shade */
  }

  .toolcard-chip.tag-finance{
    color: #33c666; /* Darker raspberry shade */
    background-color: #d2f2d1; /* Light raspberry shade */
  }

  .toolcard-chip.tag-outdoors{
    color: #33c666; /* Darker raspberry shade */
    background-color: #d2f2d1; /* Light raspberry shade */
  }


  .toolcard-chip.tag-mental-health{
    color: #c6ad33; /* Darker raspberry shade */
    background-color: #d1f2dd; /* Light raspberry shade */
  }

  .toolcard-chip.tag-ai-podcast-tools{
    color: #c6ad33; /* Darker raspberry shade */
    background-color: #d1dcf2; /* Light raspberry shade */
  }

  .toolcard-chip.tag-lifestyle{
    color: #33c666; /* Darker raspberry shade */
    background-color: #d2f2d1; /* Light raspberry shade */
  }

  .toolcard-chip.tag-health-and-fitness{
    color: #3389c6; /* Darker raspberry shade */
    background-color: #d1e4f2; /* Light raspberry shade */
  }

  .toolcard-chip.tag-sports{
    color: #3353c6; /* Darker raspberry shade */
    background-color: #d1d7f2; /* Light raspberry shade */
  }

  .toolcard-chip.tag-police-stories{
    color: #3353c6; /* Darker raspberry shade */
    background-color: #d1d7f2; /* Light raspberry shade */
  }

  .toolcard-chip.tag-food-and-cooking{
    color: #c633bf; /* Darker raspberry shade */
    background-color: #efd1f2; /* Light raspberry shade */
  }



  .toolcard-chip.tag-music{
    color: #c63333; /* Darker raspberry shade */
    background-color: #f2d1d1; /* Light raspberry shade */
  }

  .toolcard-chip.tag-arts{
    color: #3353c6; /* Darker raspberry shade */
    background-color: #d1d7f2; /* Light raspberry shade */
  }

  .toolcard-chip.tag-games{
    color: #62c633; /* Darker raspberry shade */
    background-color: #d1f2d4; /* Light raspberry shade */
  }

  .toolcard-chip.tag-relationships{
    color: #c6339a; /* Darker raspberry shade */
    background-color: #f2d1dd; /* Light raspberry shade */
  }

  .toolcard-chip.tag-prison-life{
    color: #c63333; /* Darker raspberry shade */
    background-color: #d1b9f8; /* Light raspberry shade */
  }


  .toolcard-chip.tag-survival-stories{
    color: #33c6ad; /* Darker raspberry shade */
    background-color: #b9f8d1; /* Light raspberry shade */
  }

  .toolcard-chip.tag-paranormal{
    color: #b366f7; /* Darker mango shade */
    background-color: #f1e2ff; /* Light mango shade */
  }


  .toolcard-chip.tag-criminal-lifestyle{
    color: #c633bf; /* Darker raspberry shade */
    background-color: #efd1f2; /* Light raspberry shade */
  }


  .toolcard-chip.tag-espionage-and-spy-stories{
    color: #c69733; /* Darker raspberry shade */
    background-color: #d1e6f2; /* Light raspberry shade */
  }

  .toolcard-chip.tag-artificial-intelligence{
    color: #33c6ad; /* Darker raspberry shade */
    background-color: #b9f8d1; /* Light raspberry shade */
  }


  .price-free {
    color:  #235edc;
  }
  
  .price-freemium {
    color:  #6FA83A;
  }
  
  .price-paid {
    color: #A74AC7;
  }



  @media (max-width: 600px) {
    #the-card {
      margin-right: 'auto';
      margin-left: 'auto';
      display: flex;
      justify-content: space-between;
    }

  }
  

  #price-label {
    position: relative;
   
    text-align: right !important;
    font-size: 14px;
  }


  .priceClass {
    display: inline-block; /* To make sure it doesn't stretch unnecessarily */
  padding: 5px 15px;  /* Padding around the text to create the pill shape */
  border-radius: 20px; /* A high border-radius to create the pill shape */
  background-color: #3498db; /* Blue background color */
  color: white;  /* White text for contrast */
  font-size: 14px; /* Adjust font size as needed */
  text-align: center;
  }



  .centered-container {
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center; /* Vertical centering (if needed) */
    text-decoration: 'none';
  }

  .centered-container a {
    text-decoration: 'none';
  }

  #visit-website-button {
    padding: 5px 15px;
    align-items: center;
    align-content: center;
    margin-bottom: 0px;
    background-color: #f1f1f1; /* Green background */
    color: #333; /* White text */
    border: none;
    font-family: 'Bricolage Grotesque';
    border-radius: 8px;
    box-shadow: 0px 1px 2px #555;
    cursor: pointer;
    box-shadow: 0px 2px 3px #333;
    text-decoration: 'none' !important;
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    font-size: 12px;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }

  
  
  #visit-website-button:hover {
    background-color: #d3d1d3; /* Darker shade of green on hover */
  }
  
  .button-icon {
    margin-left: 8px; /* Space between text and icon */
    font-size: 14px;
  }



  .product-of-the-month {
    font-style: italic; /* Italicize the text */
    writing-mode: vertical-lr; /* Rotate the text vertically */
    transform: rotate(180deg); /* Flip the text */
    margin: 0 auto; /* Adjust spacing */
    white-space: nowrap; /* Prevent wrapping */
    font-size: 14px;
    color: #333;
    position: absolute;
    left: -20px;
    top: 100px;
  
    
  }


  .product-of-the-month {
    position: absolute;
    top: 0;
  }
  
  @media (max-width: 600px) {
    .product-of-the-month.mobile {
      position: relative;
      left: -12em;
      top: 17em;
    }
  }
  
  .product-of-the-month.desktop {
    right: 21.5em; /* Adjust the right position for desktop */
    top: 16em;
  }



