.newsletter-button {
    background-color: #2C3E50; /* Blue background */
    color: #fff; /* White text */
    border: none; /* No border */
    padding: 10px 20px; /* Padding */
    font-size: 14px; /* Font size */
    cursor: pointer; /* Pointer cursor */
    border-radius: 6px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition */
    display: flex;
    justify-content: center;
    margin: 0 auto !important;
    font-weight: 500;
    position: 'relative';
    margin-bottom: '20em';
    
  }
  
  .newsletter-button:hover {
    background-color: #2C3E50; /* Darker blue on hover */
  }


  .home-button {
    background-color: #2C3E50; /* Blue background */
    color: #fff; /* White text */
    border: none; /* No border */
    padding: 10px 20px; /* Padding */
    font-size: 14px; /* Font size */
    cursor: pointer; /* Pointer cursor */
    border-radius: 6px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition */
    display: flex;
    justify-content: center;
    margin: 0 auto;
    font-weight: 500;
    position: 'relative';
    margin-bottom: '20em';
    
  }
  
  .home-button:hover {
    background-color: #2C3E50; /* Darker blue on hover */
  }