.topbar {
    width: 100%;
    height: 18px; /* Adjust height as needed */
    background: #fcaa97;
    color: white; /* Text color */
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .topbar-content {
    max-width: 90%; /* Adjust content width as needed */
    display: flex;
  }
  
  .topbar p {
    margin: 0;
    font-size: 13px; /* Adjust font size as needed */
    color: 'white';
    display: flex;
  }
  
