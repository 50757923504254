/* Existing CSS */

.footer {
    background-color: #f7f1e3;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    width: 100%;
}

.footer-container {
    width: 100%;
    
    padding: 0 20px; /* Adds some padding on the sides */
    margin: 0 auto;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items to the top */
    margin-bottom: 20px;
}

.left-half, .right-half {
    flex: 1;
    padding: 10px;
}

.branding h2 {
    font-size: 20px;
    font-weight: bold;
}

.links {
    display: flex;
    margin-top: 10px;
    
}

.links .column {
    margin-right: 20px; /* Space between columns */
}

.links a {
    display: block;
    margin-bottom: 5px;
    color: #333;
    text-decoration: none;
    
}

.links a:hover {
    text-decoration: underline;
}

.social-media {
    margin-top: 25px;
    display: flex;

}



.social-media a {
    margin-right: 10px;
    text-decoration: none;
}

.social-media img {
    width: 35px;
    height: 35px;
}

.newsletter-cta {
    text-align: left;
}

.newsletter-cta h3 {
    margin-bottom: 10px;
    font-size: 20px;
}

.newsletter-cta input {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.newsletter-cta button {
    padding: 11px 20px;
    background-color: #fcaa97;
    color: #fff;
    border: none;
    cursor: pointer;
}

.footer-bottom {
    text-align: center;
 
    padding-top: 10px;
}

.footer-bottom a {
    margin: 0 10px;
    color: #6c757d;
    text-decoration: none;
}

.footer-bottom a:hover {
    text-decoration: underline;
}

/* Media Query for Larger Screens */
@media (min-width: 992px) {
    .footer-content {
        align-items: flex-start; /* Align items to the top */
        margin-left: -20px; /* Adjust negative margin to push content outwards */
        margin-right: -20px; /* Adjust negative margin to push content outwards */
    }

    .left-half, .right-half {
        padding: 20px; /* Increase padding for space */
    }

    .links .column {
        margin-right: 40px; /* Increase space between columns */
    }
}

/* Media Query for Smaller Screens (Mobile) */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column; /* Stack items vertically on mobile */
        align-items: center; /* Center align items on mobile */
    }

    .left-half, .right-half {
        width: 100%; /* Full width for both halves on mobile */
        padding: 0; /* Remove padding on mobile */
    }

    .links .column {
        margin-right: 40px; /* Reset margin between columns on mobile */
        margin-bottom: 10px; /* Add margin between links on mobile */
    }

    .left-half {
        margin-top: '0em';
    }
}
