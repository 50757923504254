.scroll-to-top-button {
    position: fixed;
    border: solid 1px grey;
    bottom: 50px;
    left: 35em;
    background-color:  white;
    height: 25px;
    width: 25px;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2); 
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }
  
 
  
  .scroll-to-top-button svg {
    width: 14px;
    height: 10px;
  }
  
  #up-arrow3 {
    font-size: 14px;
    position: relative;
    right:5px;
    bottom: 6px;
    color: 'white';
  }





#up-arrow {
    position: relative;
    bottom: '3px';
    color: 'white';
}



  
.second-scrolly {
  position: fixed;
  border: solid 1px grey;
  bottom: 50px;
  right: 0em;
  background-color:  white;
  height: 25px;
  width: 25px;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2); 
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}



.second-scrolly svg {
  width: 14px;
  height: 10px;
}

.up-arrow1 {
  font-size: 24px !important;
  position: relative;
  right:5px;
  bottom: 6px;
  color: 'white';
}
