.timeline {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  padding: 10px;
  margin-top: 10em;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 4px;
  background-color: #333;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
  margin-top: 0px;
}

.container-time {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  margin-bottom: 50px;
  
}

.left::after,
.right::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: white;
  border: 4px solid rgb(140, 212, 162);
  border-radius: 50%;
  z-index: 1;
  top: 37px;

}

.left {
  left: 0%;
  
}

.right {
  left: 50%;
}

.left::after {
  right: 69px;
  
}

.right::after {
  left: -12px;
}

.content {
  padding: 20px 30px;
  background-color: transparent;
  color: white;
  position: relative;
  bottom: 10px;
  border-radius: 6px;
  right: 35px;
  margin-bottom: 0px;
  
}

/* Media query for smaller screens (below 600px) */
@media (max-width: 600px) {
  .content {
    margin-left: 60px; /* Set margin-left to 20px for smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
    text-align: left; /* Ensure text is aligned to the left */
  }

 
}

.content h2 {
  font-size: 20px;
  color: black;
}

.content p {
  color: #333;
  font-size: 16px;
}

.dots {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .timeline {
    flex-direction: column; /* Stack timeline vertically on smaller screens */
    max-width: 100%; /* Ensure full width */
  }

  .timeline::after {
    left: 36px; /* Adjust central line position */
  }

  .container-time {
    width: 100%; /* Full width on mobile */
    padding: 10px 20px; /* Adjust padding */
  }



  .content {
    padding: 10px; /* Smaller padding for mobile */
  }

  .left {
    text-align: left; /* Align all text to the left on mobile */
  }

  .right {
    left: 0%;
  }

  .left::after,
  .right::after {
    left: 15px; /* Ensure nodes are aligned */
  }
}
  .circle-green::after {
    background-color: white;
    border: 4px solid rgb(140, 212, 162); /* Green */
    border-radius: 50%;
}

.circle-blue::after {
    background-color: white;
    border: 4px solid rgb(85, 172, 238); /* Blue */
    border-radius: 50%;
}

.circle-red::after {
    background-color: white;
    border: 4px solid rgb(238, 85, 85); /* Red */
    border-radius: 50%;
}

.circle-yellow::after {
  background-color: white;
  border: 4px solid rgb(85, 238, 238); /* Red */
  border-radius: 50%;
}


.circle-purple::after {
  background-color: white;
  border: 4px solid rgb(184, 85, 238); /* Red */
  border-radius: 50%;
}

.circle-orange::after {
  background-color: white;
  border: 4px solid rgb(238, 184, 85); /* Red */
  border-radius: 50%;
}


.circle-pink:after {
  background-color: white;
  border: 4px solid rgb(238, 85, 179); /* Red */
  border-radius: 50%;
}






  .red::marker {
    color: #f4aacd;
  }

  .blue::marker {
    color: #69b4ff;
  }

  .green::marker {
    color:  rgb(140, 212, 162);
  }

  .yellow::marker {
    color: #ffe181;
  }

  .orange::marker {
    color: orange;
  }

  .aqua::marker {
    color: rgb(0, 229, 255);
  }

  .purple::marker {
    color: rgb(149, 0, 255);
  }

  .pink::marker {
    color: rgb(255, 0, 208);
  }




 .dark-text-timeline {
  position: relative;
  bottom: 0em;
  padding: 20px;
 }

 #course-subheader-6 {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  

 }

 #single-guide-header6 {
  font-size: 20px;
  margin-top: 2.7em;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2em;
 
 }

 .course-subheader-22 {
  font-size: 16px;
  font-weight: 500;
  
  text-align: center;
  line-height: 1.5;
  
  padding: 5 em; /* Ensure padding on both sides */
 }