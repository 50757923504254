.popup {
    position: fixed;
    bottom: 0px;
    right: 20px;
    width: 300px;
    background-color: #e7e1d1;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px #888;
    z-index: 1000;
    transition: transform .3s ease-in-out; /* Smooth transition for transform (slide-up animation) */
    transform: translateY(0);
  }
  
  .popup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    font-weight: bold;
    text-align: 'center';
    color: #333;
    margin-bottom: 10px;
    position: relative; /* Added for positioning */
  }
  
  .popup-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px; /* Adjust height as needed */
    background-color: #333;
    border-radius: 10px 10px 0 0; /* Rounded corners */
  }
  
  .popup-header-text {
    flex: 1; /* Ensures text stays left-aligned */
  }
  
  .popup-arrow {
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .popup-content {
    text-align: center;
    
  }
  
  .popup-subheader {
    font-size: 14px;
    color: black;
    margin-bottom: 20px;
  }
  
  .popup-button {
    width: 100%;
    padding: 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  
  .popup.collapsed {
    height: 35px; /* Adjust this height as needed */
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    .popup {
      bottom: 0px;
      right: 50%;
      transform: translateX(50%);
      width: 90%;
    }
  }
  